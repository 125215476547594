// Remove when you find out about chartMap error in xSpreadSheetHelper

export const _chartMap = {
  "structure-comparison-bar": {
    id: "structure-comparison-bar",
    title: "Vertical comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/structure/images/structure-comparison-bar.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
          },
        },
      },
    },
    settings: {
      chartType: 2,
      showVerticalCharts: true,
    },
  },
  "structure-comparison-dot": {
    id: "structure-comparison-dot",
    title: "Vertical comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/structure/images/structure-comparison-dot.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
          },
        },
      },
    },
    settings: {
      chartType: 7,
      showVerticalCharts: true,
    },
  },
  "structure-series-comparison-bar": {
    id: "structure-series-comparison-bar",
    title: "Series comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/structure/images/structure-series-comparison-bar.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
            "2": {
              text: "160",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "110",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
            "2": {
              text: "105",
            },
          },
        },
      },
    },
    settings: {
      chartType: 3,
      showVerticalCharts: true,
      chartLayout: "Actual",
    },
  },
  "time-comparison-column": {
    id: "time-comparison-column",
    title: "Horizontal comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/time/images/time-comparison-column.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
          },
        },
      },
    },
    settings: {
      chartType: 2,
      showVerticalCharts: false,
    },
  },
  "time-comparison-line": {
    id: "time-comparison-line",
    title: "Horizontal comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/time/images/time-comparison-line.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
          },
        },
      },
    },
    settings: {
      chartType: 4,
      showVerticalCharts: false,
    },
  },
  "time-comparison-area": {
    id: "time-comparison-area",
    title: "Horizontal comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/time/images/time-comparison-area.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
          },
        },
      },
    },
    settings: {
      chartType: 1,
      showVerticalCharts: false,
    },
  },
  "time-comparison-dot": {
    id: "time-comparison-dot",
    title: "Horizontal comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/time/images/time-comparison-dot.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
          },
        },
      },
    },
    settings: {
      chartType: 7,
      showVerticalCharts: false,
    },
  },
  "time-series-comparison-column": {
    id: "time-series-comparison-column",
    title: "Series comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/time/images/time-series-comparison-column.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 3,
      showVerticalCharts: false,
      chartLayout: "Actual",
    },
  },
  "time-series-comparison-line": {
    id: "time-series-comparison-line",
    title: "Series comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/time/images/time-series-comparison-line.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 4,
      showVerticalCharts: false,
    },
  },
  "time-small-multiples-1": {
    id: "time-small-multiples-1",
    title: "Series comparison chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/time/images/time-small-multiples-1.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Month",
            },
            "1": {
              text: "Audio",
            },
            "2": {
              text: "Baby Care",
            },
            "3": {
              text: "Comp",
            },
            "4": {
              text: "Hair Care",
            },
            "5": {
              text: "Mobile",
            },
            "6": {
              text: "Oral Care",
            },
            "7": {
              text: "Skin Care",
            },
            "8": {
              text: "Video",
            },
            "9": {
              text: "Wearable",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "83329",
            },
            "2": {
              text: "338313",
            },
            "3": {
              text: "106422",
            },
            "4": {
              text: "76442",
            },
            "5": {
              text: "233961",
            },
            "6": {
              text: "4372",
            },
            "7": {
              text: "8635",
            },
            "8": {
              text: "41307",
            },
            "9": {
              text: "347659",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "113952",
            },
            "2": {
              text: "312216",
            },
            "3": {
              text: "26272",
            },
            "4": {
              text: "127250",
            },
            "5": {
              text: "199055",
            },
            "6": {
              text: "2755",
            },
            "7": {
              text: "7622",
            },
            "8": {
              text: "66154",
            },
            "9": {
              text: "311798",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "215457",
            },
            "2": {
              text: "295621",
            },
            "3": {
              text: "181653",
            },
            "4": {
              text: "187766",
            },
            "5": {
              text: "239958",
            },
            "6": {
              text: "20280",
            },
            "7": {
              text: "4935",
            },
            "8": {
              text: "49844",
            },
            "9": {
              text: "309386",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "178617",
            },
            "2": {
              text: "481192",
            },
            "3": {
              text: "65716",
            },
            "4": {
              text: "120910",
            },
            "5": {
              text: "299027",
            },
            "6": {
              text: "1482",
            },
            "7": {
              text: "9518",
            },
            "8": {
              text: "67001",
            },
            "9": {
              text: "363589",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "158154",
            },
            "2": {
              text: "288304",
            },
            "3": {
              text: "46957",
            },
            "4": {
              text: "104938",
            },
            "5": {
              text: "207221",
            },
            "6": {
              text: "13730",
            },
            "7": {
              text: "1728",
            },
            "8": {
              text: "58116",
            },
            "9": {
              text: "310995",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "127178",
            },
            "2": {
              text: "273629",
            },
            "3": {
              text: "55603",
            },
            "4": {
              text: "115213",
            },
            "5": {
              text: "264442",
            },
            "6": {
              text: "10947",
            },
            "7": {
              text: "9055",
            },
            "8": {
              text: "59447",
            },
            "9": {
              text: "371236",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "133010",
            },
            "2": {
              text: "236845",
            },
            "3": {
              text: "10132",
            },
            "4": {
              text: "103142",
            },
            "5": {
              text: "206553",
            },
            "6": {
              text: "2451",
            },
            "7": {
              text: "2339",
            },
            "8": {
              text: "66707",
            },
            "9": {
              text: "328444",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "82613",
            },
            "2": {
              text: "329146",
            },
            "3": {
              text: "10555",
            },
            "4": {
              text: "95924",
            },
            "5": {
              text: "218048",
            },
            "6": {
              text: "4245",
            },
            "7": {
              text: "26771",
            },
            "8": {
              text: "79005",
            },
            "9": {
              text: "410819",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "68512",
            },
            "2": {
              text: "363022",
            },
            "3": {
              text: "22802",
            },
            "4": {
              text: "115577",
            },
            "5": {
              text: "220051",
            },
            "6": {
              text: "6979",
            },
            "7": {
              text: "3764",
            },
            "8": {
              text: "58415",
            },
            "9": {
              text: "339497",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "168488",
            },
            "2": {
              text: "524682",
            },
            "3": {
              text: "155838",
            },
            "4": {
              text: "163962",
            },
            "5": {
              text: "375476",
            },
            "6": {
              text: "28958",
            },
            "7": {
              text: "8702",
            },
            "8": {
              text: "64372",
            },
            "9": {
              text: "359602",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "96446",
            },
            "2": {
              text: "579961",
            },
            "3": {
              text: "9353",
            },
            "4": {
              text: "182363",
            },
            "5": {
              text: "366211",
            },
            "6": {
              text: "46304",
            },
            "7": {
              text: "4284",
            },
            "8": {
              text: "84912",
            },
            "9": {
              text: "408238",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "174985",
            },
            "2": {
              text: "109852",
            },
            "3": {
              text: "35884",
            },
            "4": {
              text: "165325",
            },
            "5": {
              text: "247100",
            },
            "6": {
              text: "15803",
            },
            "7": {
              text: "17758",
            },
            "8": {
              text: "70191",
            },
            "9": {
              text: "439920",
            },
          },
        },
      },
    },
    isMultiples: true,
    disabledComments: true,
    settings: {
      chartType: 2,
      showVerticalCharts: false,
      multiplesLayoutType: "Rows",
      showMultiplesGrid: true,
      stackedChart: false,
    },
  },
  "variance-time-column": {
    id: "variance-time-column",
    title: "Plus-minus chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/variance/images/variance-time-column.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 3,
      showVerticalCharts: false,
      chartLayout: "Absolute",
    },
  },
  "variance-time-dot": {
    id: "variance-time-dot",
    title: "Lollipop chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/variance/images/variance-time-dot.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 3,
      showVerticalCharts: false,
      chartLayout: "Relative",
    },
  },
  "integrated-variance-time-column-responsive": {
    id: "integrated-variance-time-column-responsive",
    title: "Responsive variance chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/variance/images/integrated-variance-time-column-responsive.png",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 3,
      showVerticalCharts: false,
      chartLayout: "Responsive",
    },
  },
  "integrated-variance-time-column": {
    id: "integrated-variance-time-column",
    title: "Integrated variance chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/variance/images/integrated-variance-time-column.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 3,
      showVerticalCharts: false,
      chartLayout: "Integrated",
    },
  },
  "integrated-variance-time-area": {
    id: "integrated-variance-time-area",
    title: "Hills & valleys chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/variance/images/integrated-variance-time-area.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 1,
      showVerticalCharts: false,
    },
  },
  "integrated-variance-structure-bar": {
    id: "integrated-variance-structure-bar",
    title: "Integrated variance chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/variance/images/integrated-variance-structure-bar.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
            "2": {
              text: "160",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "110",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
            "2": {
              text: "105",
            },
          },
        },
      },
    },
    settings: {
      chartType: 3,
      showVerticalCharts: true,
      chartLayout: "Integrated",
    },
  },
  "contribution-structure-column-single": {
    id: "contribution-structure-column-single",
    title: "Contribution variance chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/contribution/images/contribution-structure-column-single.png",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Account",
            },
            "1": {
              text: "Sum of Value",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "EBIT 2018",
            },
            "1": {
              text: "42807",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Revenue",
            },
            "1": {
              text: "16528",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Other op. income",
            },
            "1": {
              text: "1990",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Material",
            },
            "1": {
              text: "12030",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Personnel",
            },
            "1": {
              text: "7498",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Freight",
            },
            "1": {
              text: "-1341",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Advertising",
            },
            "1": {
              text: "262",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Other OpEx",
            },
            "1": {
              text: "-5836",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Depreciation",
            },
            "1": {
              text: "-2095",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Financial result",
            },
            "1": {
              text: "-620",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "EBIT 2019",
            },
            "1": {
              text: "50187",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "FX change",
            },
            "1": {
              text: "-1555",
            },
          },
        },
        "13": {
          cells: {
            "0": {
              text: "EBIT FX'",
            },
            "1": {
              text: "48632",
            },
          },
        },
      },
    },
    settings: {
      chartType: 0,
      showVerticalCharts: false,
      invertedCategories: ["Material", "Personnel", "Freight", "Advertising", "Other OpEx", "Depreciation"],
      resultCategories: ["EBIT 2018", "EBIT 2019", "EBIT FX'"],
    },
  },
  "contribution-time-column": {
    id: "contribution-time-column",
    title: "Contribution variance chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/contribution/images/contribution-time-column.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "130",
            },
            "2": {
              text: "165",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "162",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "122",
            },
            "2": {
              text: "150",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "125",
            },
            "2": {
              text: "145",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "156",
            },
            "2": {
              text: "145",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "182",
            },
            "2": {
              text: "150",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "200",
            },
            "2": {
              text: "170",
            },
          },
        },
      },
    },
    settings: {
      chartType: 0,
      showVerticalCharts: false,
    },
  },
  "contribution-structure-bar": {
    id: "contribution-structure-bar",
    title: "Contribution variance chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/contribution/images/contribution-structure-bar.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Category",
            },
            "1": {
              text: "ACT",
            },
            "2": {
              text: "BU",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Oranges",
            },
            "1": {
              text: "170",
            },
            "2": {
              text: "150",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Lemons",
            },
            "1": {
              text: "168",
            },
            "2": {
              text: "160",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Bananas",
            },
            "1": {
              text: "150",
            },
            "2": {
              text: "160",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Kiwi",
            },
            "1": {
              text: "140",
            },
            "2": {
              text: "170",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "Apples",
            },
            "1": {
              text: "138",
            },
            "2": {
              text: "155",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Melons",
            },
            "1": {
              text: "145",
            },
            "2": {
              text: "160",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Pears",
            },
            "1": {
              text: "120",
            },
            "2": {
              text: "110",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Grapes",
            },
            "1": {
              text: "110",
            },
            "2": {
              text: "105",
            },
          },
        },
      },
    },
    settings: {
      chartType: 0,
      showVerticalCharts: true,
    },
  },
  "part-whole-structure-bar": {
    id: "part-whole-structure-bar",
    title: "Stacked chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/partWhole/images/part-whole-structure-bar.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Month",
            },
            "1": {
              text: "Audio",
            },
            "2": {
              text: "Baby Care",
            },
            "3": {
              text: "Comp",
            },
            "4": {
              text: "Hair Care",
            },
            "5": {
              text: "Mobile",
            },
            "6": {
              text: "Oral Care",
            },
            "7": {
              text: "Skin Care",
            },
            "8": {
              text: "Video",
            },
            "9": {
              text: "Wearable",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "83329",
            },
            "2": {
              text: "338313",
            },
            "3": {
              text: "106422",
            },
            "4": {
              text: "76442",
            },
            "5": {
              text: "233961",
            },
            "6": {
              text: "4372",
            },
            "7": {
              text: "8635",
            },
            "8": {
              text: "41307",
            },
            "9": {
              text: "347659",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "113952",
            },
            "2": {
              text: "312216",
            },
            "3": {
              text: "26272",
            },
            "4": {
              text: "127250",
            },
            "5": {
              text: "199055",
            },
            "6": {
              text: "2755",
            },
            "7": {
              text: "7622",
            },
            "8": {
              text: "66154",
            },
            "9": {
              text: "311798",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "215457",
            },
            "2": {
              text: "295621",
            },
            "3": {
              text: "181653",
            },
            "4": {
              text: "187766",
            },
            "5": {
              text: "239958",
            },
            "6": {
              text: "20280",
            },
            "7": {
              text: "4935",
            },
            "8": {
              text: "49844",
            },
            "9": {
              text: "309386",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "178617",
            },
            "2": {
              text: "481192",
            },
            "3": {
              text: "65716",
            },
            "4": {
              text: "120910",
            },
            "5": {
              text: "299027",
            },
            "6": {
              text: "1482",
            },
            "7": {
              text: "9518",
            },
            "8": {
              text: "67001",
            },
            "9": {
              text: "363589",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "158154",
            },
            "2": {
              text: "288304",
            },
            "3": {
              text: "46957",
            },
            "4": {
              text: "104938",
            },
            "5": {
              text: "207221",
            },
            "6": {
              text: "13730",
            },
            "7": {
              text: "1728",
            },
            "8": {
              text: "58116",
            },
            "9": {
              text: "310995",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "127178",
            },
            "2": {
              text: "273629",
            },
            "3": {
              text: "55603",
            },
            "4": {
              text: "115213",
            },
            "5": {
              text: "264442",
            },
            "6": {
              text: "10947",
            },
            "7": {
              text: "9055",
            },
            "8": {
              text: "59447",
            },
            "9": {
              text: "371236",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "133010",
            },
            "2": {
              text: "236845",
            },
            "3": {
              text: "10132",
            },
            "4": {
              text: "103142",
            },
            "5": {
              text: "206553",
            },
            "6": {
              text: "2451",
            },
            "7": {
              text: "2339",
            },
            "8": {
              text: "66707",
            },
            "9": {
              text: "328444",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "82613",
            },
            "2": {
              text: "329146",
            },
            "3": {
              text: "10555",
            },
            "4": {
              text: "95924",
            },
            "5": {
              text: "218048",
            },
            "6": {
              text: "4245",
            },
            "7": {
              text: "26771",
            },
            "8": {
              text: "79005",
            },
            "9": {
              text: "410819",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "68512",
            },
            "2": {
              text: "363022",
            },
            "3": {
              text: "22802",
            },
            "4": {
              text: "115577",
            },
            "5": {
              text: "220051",
            },
            "6": {
              text: "6979",
            },
            "7": {
              text: "3764",
            },
            "8": {
              text: "58415",
            },
            "9": {
              text: "339497",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "168488",
            },
            "2": {
              text: "524682",
            },
            "3": {
              text: "155838",
            },
            "4": {
              text: "163962",
            },
            "5": {
              text: "375476",
            },
            "6": {
              text: "28958",
            },
            "7": {
              text: "8702",
            },
            "8": {
              text: "64372",
            },
            "9": {
              text: "359602",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "96446",
            },
            "2": {
              text: "579961",
            },
            "3": {
              text: "9353",
            },
            "4": {
              text: "182363",
            },
            "5": {
              text: "366211",
            },
            "6": {
              text: "46304",
            },
            "7": {
              text: "4284",
            },
            "8": {
              text: "84912",
            },
            "9": {
              text: "408238",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "174985",
            },
            "2": {
              text: "109852",
            },
            "3": {
              text: "35884",
            },
            "4": {
              text: "165325",
            },
            "5": {
              text: "247100",
            },
            "6": {
              text: "15803",
            },
            "7": {
              text: "17758",
            },
            "8": {
              text: "70191",
            },
            "9": {
              text: "439920",
            },
          },
        },
      },
    },
    isMultiples: true,
    disabledComments: true,
    settings: {
      chartType: 2,
      showVerticalCharts: true,
      multiplesLayoutType: "Rows",
      showMultiplesGrid: true,
      stackedChart: true,
    },
  },
  "part-whole-time-column": {
    id: "part-whole-time-column",
    title: "Stacked chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/partWhole/images/part-whole-time-column.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Month",
            },
            "1": {
              text: "Audio",
            },
            "2": {
              text: "Baby Care",
            },
            "3": {
              text: "Comp",
            },
            "4": {
              text: "Hair Care",
            },
            "5": {
              text: "Mobile",
            },
            "6": {
              text: "Oral Care",
            },
            "7": {
              text: "Skin Care",
            },
            "8": {
              text: "Video",
            },
            "9": {
              text: "Wearable",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "83329",
            },
            "2": {
              text: "338313",
            },
            "3": {
              text: "106422",
            },
            "4": {
              text: "76442",
            },
            "5": {
              text: "233961",
            },
            "6": {
              text: "4372",
            },
            "7": {
              text: "8635",
            },
            "8": {
              text: "41307",
            },
            "9": {
              text: "347659",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "113952",
            },
            "2": {
              text: "312216",
            },
            "3": {
              text: "26272",
            },
            "4": {
              text: "127250",
            },
            "5": {
              text: "199055",
            },
            "6": {
              text: "2755",
            },
            "7": {
              text: "7622",
            },
            "8": {
              text: "66154",
            },
            "9": {
              text: "311798",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "215457",
            },
            "2": {
              text: "295621",
            },
            "3": {
              text: "181653",
            },
            "4": {
              text: "187766",
            },
            "5": {
              text: "239958",
            },
            "6": {
              text: "20280",
            },
            "7": {
              text: "4935",
            },
            "8": {
              text: "49844",
            },
            "9": {
              text: "309386",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "178617",
            },
            "2": {
              text: "481192",
            },
            "3": {
              text: "65716",
            },
            "4": {
              text: "120910",
            },
            "5": {
              text: "299027",
            },
            "6": {
              text: "1482",
            },
            "7": {
              text: "9518",
            },
            "8": {
              text: "67001",
            },
            "9": {
              text: "363589",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "158154",
            },
            "2": {
              text: "288304",
            },
            "3": {
              text: "46957",
            },
            "4": {
              text: "104938",
            },
            "5": {
              text: "207221",
            },
            "6": {
              text: "13730",
            },
            "7": {
              text: "1728",
            },
            "8": {
              text: "58116",
            },
            "9": {
              text: "310995",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "127178",
            },
            "2": {
              text: "273629",
            },
            "3": {
              text: "55603",
            },
            "4": {
              text: "115213",
            },
            "5": {
              text: "264442",
            },
            "6": {
              text: "10947",
            },
            "7": {
              text: "9055",
            },
            "8": {
              text: "59447",
            },
            "9": {
              text: "371236",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "133010",
            },
            "2": {
              text: "236845",
            },
            "3": {
              text: "10132",
            },
            "4": {
              text: "103142",
            },
            "5": {
              text: "206553",
            },
            "6": {
              text: "2451",
            },
            "7": {
              text: "2339",
            },
            "8": {
              text: "66707",
            },
            "9": {
              text: "328444",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "82613",
            },
            "2": {
              text: "329146",
            },
            "3": {
              text: "10555",
            },
            "4": {
              text: "95924",
            },
            "5": {
              text: "218048",
            },
            "6": {
              text: "4245",
            },
            "7": {
              text: "26771",
            },
            "8": {
              text: "79005",
            },
            "9": {
              text: "410819",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "68512",
            },
            "2": {
              text: "363022",
            },
            "3": {
              text: "22802",
            },
            "4": {
              text: "115577",
            },
            "5": {
              text: "220051",
            },
            "6": {
              text: "6979",
            },
            "7": {
              text: "3764",
            },
            "8": {
              text: "58415",
            },
            "9": {
              text: "339497",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "168488",
            },
            "2": {
              text: "524682",
            },
            "3": {
              text: "155838",
            },
            "4": {
              text: "163962",
            },
            "5": {
              text: "375476",
            },
            "6": {
              text: "28958",
            },
            "7": {
              text: "8702",
            },
            "8": {
              text: "64372",
            },
            "9": {
              text: "359602",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "96446",
            },
            "2": {
              text: "579961",
            },
            "3": {
              text: "9353",
            },
            "4": {
              text: "182363",
            },
            "5": {
              text: "366211",
            },
            "6": {
              text: "46304",
            },
            "7": {
              text: "4284",
            },
            "8": {
              text: "84912",
            },
            "9": {
              text: "408238",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "174985",
            },
            "2": {
              text: "109852",
            },
            "3": {
              text: "35884",
            },
            "4": {
              text: "165325",
            },
            "5": {
              text: "247100",
            },
            "6": {
              text: "15803",
            },
            "7": {
              text: "17758",
            },
            "8": {
              text: "70191",
            },
            "9": {
              text: "439920",
            },
          },
        },
      },
    },
    isMultiples: true,
    disabledComments: true,
    settings: {
      chartType: 2,
      showVerticalCharts: false,
      multiplesLayoutType: "Rows",
      showMultiplesGrid: true,
      stackedChart: true,
    },
  },
  "part-whole-time-area": {
    id: "part-whole-time-area",
    title: "Stacked chart",
    image:
      "https://localhost:3000/packages/chart-chooser/react/charts/categories/dimensions/partWhole/images/part-whole-time-area.svg",
    sampleData: {
      rows: {
        "0": {
          cells: {
            "0": {
              text: "Month",
            },
            "1": {
              text: "Audio",
            },
            "2": {
              text: "Baby Care",
            },
            "3": {
              text: "Comp",
            },
            "4": {
              text: "Hair Care",
            },
            "5": {
              text: "Mobile",
            },
            "6": {
              text: "Oral Care",
            },
            "7": {
              text: "Skin Care",
            },
            "8": {
              text: "Video",
            },
            "9": {
              text: "Wearable",
            },
          },
        },
        "1": {
          cells: {
            "0": {
              text: "Jan",
            },
            "1": {
              text: "83329",
            },
            "2": {
              text: "338313",
            },
            "3": {
              text: "106422",
            },
            "4": {
              text: "76442",
            },
            "5": {
              text: "233961",
            },
            "6": {
              text: "4372",
            },
            "7": {
              text: "8635",
            },
            "8": {
              text: "41307",
            },
            "9": {
              text: "347659",
            },
          },
        },
        "2": {
          cells: {
            "0": {
              text: "Feb",
            },
            "1": {
              text: "113952",
            },
            "2": {
              text: "312216",
            },
            "3": {
              text: "26272",
            },
            "4": {
              text: "127250",
            },
            "5": {
              text: "199055",
            },
            "6": {
              text: "2755",
            },
            "7": {
              text: "7622",
            },
            "8": {
              text: "66154",
            },
            "9": {
              text: "311798",
            },
          },
        },
        "3": {
          cells: {
            "0": {
              text: "Mar",
            },
            "1": {
              text: "215457",
            },
            "2": {
              text: "295621",
            },
            "3": {
              text: "181653",
            },
            "4": {
              text: "187766",
            },
            "5": {
              text: "239958",
            },
            "6": {
              text: "20280",
            },
            "7": {
              text: "4935",
            },
            "8": {
              text: "49844",
            },
            "9": {
              text: "309386",
            },
          },
        },
        "4": {
          cells: {
            "0": {
              text: "Apr",
            },
            "1": {
              text: "178617",
            },
            "2": {
              text: "481192",
            },
            "3": {
              text: "65716",
            },
            "4": {
              text: "120910",
            },
            "5": {
              text: "299027",
            },
            "6": {
              text: "1482",
            },
            "7": {
              text: "9518",
            },
            "8": {
              text: "67001",
            },
            "9": {
              text: "363589",
            },
          },
        },
        "5": {
          cells: {
            "0": {
              text: "May",
            },
            "1": {
              text: "158154",
            },
            "2": {
              text: "288304",
            },
            "3": {
              text: "46957",
            },
            "4": {
              text: "104938",
            },
            "5": {
              text: "207221",
            },
            "6": {
              text: "13730",
            },
            "7": {
              text: "1728",
            },
            "8": {
              text: "58116",
            },
            "9": {
              text: "310995",
            },
          },
        },
        "6": {
          cells: {
            "0": {
              text: "Jun",
            },
            "1": {
              text: "127178",
            },
            "2": {
              text: "273629",
            },
            "3": {
              text: "55603",
            },
            "4": {
              text: "115213",
            },
            "5": {
              text: "264442",
            },
            "6": {
              text: "10947",
            },
            "7": {
              text: "9055",
            },
            "8": {
              text: "59447",
            },
            "9": {
              text: "371236",
            },
          },
        },
        "7": {
          cells: {
            "0": {
              text: "Jul",
            },
            "1": {
              text: "133010",
            },
            "2": {
              text: "236845",
            },
            "3": {
              text: "10132",
            },
            "4": {
              text: "103142",
            },
            "5": {
              text: "206553",
            },
            "6": {
              text: "2451",
            },
            "7": {
              text: "2339",
            },
            "8": {
              text: "66707",
            },
            "9": {
              text: "328444",
            },
          },
        },
        "8": {
          cells: {
            "0": {
              text: "Aug",
            },
            "1": {
              text: "82613",
            },
            "2": {
              text: "329146",
            },
            "3": {
              text: "10555",
            },
            "4": {
              text: "95924",
            },
            "5": {
              text: "218048",
            },
            "6": {
              text: "4245",
            },
            "7": {
              text: "26771",
            },
            "8": {
              text: "79005",
            },
            "9": {
              text: "410819",
            },
          },
        },
        "9": {
          cells: {
            "0": {
              text: "Sep",
            },
            "1": {
              text: "68512",
            },
            "2": {
              text: "363022",
            },
            "3": {
              text: "22802",
            },
            "4": {
              text: "115577",
            },
            "5": {
              text: "220051",
            },
            "6": {
              text: "6979",
            },
            "7": {
              text: "3764",
            },
            "8": {
              text: "58415",
            },
            "9": {
              text: "339497",
            },
          },
        },
        "10": {
          cells: {
            "0": {
              text: "Oct",
            },
            "1": {
              text: "168488",
            },
            "2": {
              text: "524682",
            },
            "3": {
              text: "155838",
            },
            "4": {
              text: "163962",
            },
            "5": {
              text: "375476",
            },
            "6": {
              text: "28958",
            },
            "7": {
              text: "8702",
            },
            "8": {
              text: "64372",
            },
            "9": {
              text: "359602",
            },
          },
        },
        "11": {
          cells: {
            "0": {
              text: "Nov",
            },
            "1": {
              text: "96446",
            },
            "2": {
              text: "579961",
            },
            "3": {
              text: "9353",
            },
            "4": {
              text: "182363",
            },
            "5": {
              text: "366211",
            },
            "6": {
              text: "46304",
            },
            "7": {
              text: "4284",
            },
            "8": {
              text: "84912",
            },
            "9": {
              text: "408238",
            },
          },
        },
        "12": {
          cells: {
            "0": {
              text: "Dec",
            },
            "1": {
              text: "174985",
            },
            "2": {
              text: "109852",
            },
            "3": {
              text: "35884",
            },
            "4": {
              text: "165325",
            },
            "5": {
              text: "247100",
            },
            "6": {
              text: "15803",
            },
            "7": {
              text: "17758",
            },
            "8": {
              text: "70191",
            },
            "9": {
              text: "439920",
            },
          },
        },
      },
    },
    isMultiples: true,
    disabledComments: true,
    settings: {
      chartType: 1,
      showVerticalCharts: false,
      multiplesLayoutType: "Rows",
      showMultiplesGrid: true,
      stackedChart: true,
    },
  },
};
